'use strict';

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import lightBlue from '@material-ui/core/colors/lightBlue';

// see: https://material.io/tools/color/
export default createMuiTheme({
  palette: {

    // blueGrey50
    /*primary: {
      main: '#eceff1',
      light: '#ffffff',
      dark: '#babdbe',
      contrastText: '#000000',
    },
    // blueGrey300
    secondary: {
      main: '#90a4ae',
      light: '#c1d5e0',
      dark: '#62757f',
      contrastText: '#000000',
    },*/

    // blueGrey500
    primary: {
      main: '#607d8b',
      light: '#8eacbb',
      dark: '#34515e',
      contrastText: '#000000',
    },
    // custom
    secondary: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#babdbe',
      contrastText: '#000000',
    },
  },

  overrides: {

    // see: https://material-ui-pickers.firebaseapp.com/guides/css-overrides
    MuiPickersToolbar: {
      toolbar: {
        // we simply hide the toolbar to be near on a drop-down-chooser
        display: 'none',
      },
    },
  },
});