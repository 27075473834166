'use strict';

import * as React from 'react';
import ReactDOM from 'react-dom';

// polyfill still needed? If so, we have to switch to 'timers-browserify'
// ==> https://www.npmjs.com/package/timers-browserify
//import { setTimeout } from 'timers';

import createEventer from './eventer';
import { EventerApp } from './app/eventer';


// bind error logging at first
import { logError } from './eventer/common/common';
window.onerror = (e) => logError(e, 'eventer.app/index.js');


function _initialize() {

  if ('serviceWorker' in navigator) {
    console.log('start registration of service-worker..');
    navigator.serviceWorker.register('sw.js', { scope: './' })
      .then(() => {
        console.log('service-worker registration done');
      });
  }

  // initialize eventer
  const eventer = createEventer();
  eventer.initialize()
    .then(() => {

      // render app
      ReactDOM.render(
        <EventerApp eventer={ eventer } />, document.getElementById('eventer-app'))


      // do some clean up (async, delayed)
      setTimeout(eventer.cleanUp, 3000);
    });
}

setTimeout(_initialize, 10);
