'use strict';

// TODO: https://github.com/webpack-contrib/worker-loader#integrating-with-typescript
import EventerWorker from './eventer-worker';


export default () => {

  const _self = {
    msgId: 1,
    eventerWorker: new EventerWorker(),
    calls: {}
  };
  const _eventer = {};


  _self.eventerWorker.onmessage = (e) => {

    const msg = e.data;
    console.log('[eventer] got message (from worker): ', msg);

    const call = _self.calls[msg.id];
    if (!call) {
      console.log('no pending call found for message: ', msg);
    }

    if (msg.hasOwnProperty('error')) {
      call.promiseRejecter.call(undefined, new Error(msg.error));
      delete _self.calls[msg.id];
    }
    
    if (msg.hasOwnProperty('payload')) {
      if (msg.completed) {
        call.promiseResolver.call(undefined, msg.payload);
        delete _self.calls[msg.id];
      } else {
        if (call.flux) {
          call.flux(msg.payload);
        }
      }
    }
  };

  
  const _generateMsgId = () => _self.msgId++;
  const _postMessage = (type, payload, flux) => {

    const msgId = _generateMsgId();
    _self.calls[msgId] = {
      message: {
        id: msgId,
        type: type,
        payload: payload,
        flux: !!flux
      },
      flux: flux
    };

    _self.eventerWorker.postMessage(_self.calls[msgId].message);

    return new Promise((promiseResolver, promiseRejecter) => {
      _self.calls[msgId].promiseResolver = promiseResolver;
      _self.calls[msgId].promiseRejecter = promiseRejecter;
    });
  }

  _eventer.initialize = () => {
    console.log("initialize eventer");
    return _postMessage('initialize');
  };
  _eventer.loadEvents = (params) => {
    return _postMessage('loadEvents', params);
  };
  _eventer.searchEvents = (params) => {
    return _postMessage('searchEvents', params);
  };
  _eventer.syncEvents = (params, eventsFlux) => {
    return _postMessage('syncEvents', params, eventsFlux);
  };
  _eventer.cleanUp = (params) => {
    return _postMessage('cleanUp', params);
  };

  return _eventer;
};